var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mb-10",attrs:{"fluid":""}},[_c('v-row',{staticClass:"ml-7",staticStyle:{"width":"96%"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pa-5"},[_c('h3',[_vm._v(" Edit Image : "),_c('span',{staticClass:"ml-2 font-weight-regular"},[_vm._v(_vm._s(_vm.product_name))])]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-file-input',{attrs:{"color":"deep-purple accent-4","counter":"","dense":"","label":"Upload image","multiple":"","placeholder":"Select your images","prepend-icon":"mdi-camera","outlined":"","show-size":"","accept":"image/png, image/jpeg, image/jpg"},on:{"click:clear":_vm.deleteAllImage,"change":_vm.Preview_image},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var index = ref.index;
var text = ref.text;
return [(index < 6)?_c('v-chip',{attrs:{"color":"deep-purple accent-4","dark":"","label":"","small":""}},[_vm._v(_vm._s(text))]):(index === 2)?_c('span',{staticClass:"overline grey--text text--darken-3 mx-2"},[_vm._v("+"+_vm._s(_vm.images.length - 2)+" File(s)")]):_vm._e()]}}]),model:{value:(_vm.images),callback:function ($$v) {_vm.images=$$v},expression:"images"}})],1)],1),_c('v-row',[(_vm.addItem.image.length > 0)?_c('v-container',[_c('p',[_vm._v("New Images :")]),_c('v-col',{staticClass:"d-flex flex-row flex-wrap"},_vm._l((_vm.addItem.image),function(image,index){return _c('v-tooltip',{key:index,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticClass:"mr-2 mb-4 thumbnailImage",style:([
                      image.excede
                        ? { border: '4px solid red' }
                        : { border: '4px solid green' } ]),attrs:{"src":image.image,"max-height":"200","max-width":"200"}},'v-img',attrs,false),on),[_c('v-icon',{staticClass:"float-right",attrs:{"color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteImage(index)}}},[_vm._v("mdi-close-thick")])],1)]}}],null,true)})}),1)],1):_vm._e()],1),_c('v-row',[(_vm.fetchedImages.length > 0)?_c('v-container',[_c('p',[_vm._v("Old Images :")]),_c('v-col',{staticClass:"d-flex flex-row flex-wrap"},_vm._l((_vm.fetchedImages),function(image,index){return _c('v-tooltip',{key:index,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticClass:"mr-2 mb-4 thumbnailImage",attrs:{"src":image.image_url,"max-height":"200","max-width":"200"}},'v-img',attrs,false),on),[_c('v-icon',{staticClass:"float-right",attrs:{"color":"red"},on:{"click":function($event){return _vm.DeleteOldImage(image)}}},[_vm._v("mdi-close-thick")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete Image")])])}),1)],1):_vm._e()],1),_c('v-row',{attrs:{"justify":"end"}},[_c('v-btn',{attrs:{"color":this.addItem.image.length + this.fetchedImages.length > 6
                ? 'red'
                : 'primary'},on:{"click":_vm.save}},[_vm._v("Save")])],1)],1)],1)],1),_c('v-dialog',{staticClass:"ma-0",attrs:{"max-width":"1050px"},model:{value:(_vm.showCrop),callback:function ($$v) {_vm.showCrop=$$v},expression:"showCrop"}},[_c('v-card',[_c('v-row',{staticClass:"ma-0"},[_c('v-col',[_c('div',{staticClass:"cut"},[_c('vue-cropper',{ref:"cropper",attrs:{"img":_vm.cropBlob.image,"output-size":_vm.option.size,"output-type":_vm.option.outputType,"info":true,"full":_vm.option.full,"fixed":_vm.fixed,"fixedNumber":_vm.fixedNumber,"can-move":_vm.option.canMove,"can-move-box":_vm.option.canMoveBox,"fixed-box":_vm.option.fixedBox,"original":_vm.option.original,"auto-crop":_vm.option.autoCrop,"auto-crop-width":_vm.option.autoCropWidth,"auto-crop-height":_vm.option.autoCropHeight,"center-box":_vm.option.centerBox,"high":_vm.option.high,"mode":"cover","max-img-size":_vm.option.max},on:{"real-time":_vm.realTime}})],1)]),_c('v-col',{staticClass:"text-center"},[_c('div',{staticClass:"show-preview",style:({
              width: _vm.previews.w + 'px',
              height: _vm.previews.h + 'px',
              overflow: 'hidden',
              border: '1px solid green',
              'margin-bottom': '10px',
            })},[_c('div',{style:(_vm.previews.div)},[_c('img',{style:(_vm.previews.img),attrs:{"src":_vm.previews.url}})])]),_c('div',{staticClass:"d-flex flex-row justify-space-between mr-6"},[_c('v-btn',{attrs:{"color":"red","tile":"","elevation":"0"},on:{"click":function($event){_vm.showCrop = false}}},[_vm._v("Go back")]),_c('v-btn',{attrs:{"color":"success","tile":"","elevation":"0","outlined":""},on:{"click":_vm.getResult}},[_vm._v("Crop & Confirm")])],1)])],1)],1)],1),_c('v-snackbar',{attrs:{"centered":"","absolute":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
            var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.text)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }